<template >
    <div class="layout" >
        <div class="topbar-container">
            <Topbar>
                <template slot="title">
                    <img class="mr10" src="@/assets/title.png" alt="">
                    <span>{{title}}</span>
                </template>
                <template slot="menu" v-if="hasMenu">
                    <Menu :menuList="routeList" :value="value" @input="menuInput"></Menu>
                </template>
                <template slot="right">
                    <div class="right">
                        <div class="need" @click="needHandleClick" v-if="hasNeedHandlePermiss && $route.name !== 'org'">
                            <span>待办</span>
                            <span class="line"></span>
                            <span class="count">{{count}}</span>
                        </div>
						<div class="icons">
							<!-- <img v-for="(icon, i) in icons" :key="i" class="icon" :src="icon" /> -->
							<!-- <img class="icon" src="@/assets/bell.png" /> -->
							<img class="icon" src="@/assets/home.png" @click="toEntrance"/>
							<!-- <img class="icon" src="@/assets/help.png" /> -->
						</div>
                        <!-- <div class="message"></div> -->
                        <div class="user">
                            <img src="@/assets/user.png" alt="">
                            <el-dropdown>
                                <span style="cursor: pointer;">
                                    <!-- <img src="@/assets/imgs/user.png" alt=""> -->
                                    <span>{{userInfo.realName}}</span>
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="userInfoView">个人信息</el-dropdown-item>
                                    <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </template>
            </Topbar>
        </div>
        <div class="body-container">
            <router-view></router-view>
        </div>

        
    </div>
</template>

<script>
import Topbar from '@/components/topbar';
import { traverse } from '@/utils/handle.js';
import { flowUserList } from '@/api/hrAPI.js';

export default {
    components: {
        Topbar,
    },
    data(){
        return {
            value: '',
            routeList: [],
            count: 0,
        }
    },
    created(){
        this.getRouteList();
        // this.$route.matched.forEach(match => {      //  回显当前菜单
        //     const route = this.routeList.find(item => item.value === match.name);
        //     if(route){
        //         console.log(route);
        //         this.value = route.value;
        //     }
        // })
        if(this.hasNeedHandlePermiss){      //  权限暂时这样解决
            flowUserList({ isSelf: true }).then(res => {        //  暂时（代办信息）
                this.count = res.total;
            })
        }
    },
    watch: {
        $route: {
            handler(val){
                this.value = val.matched[1].name;
                
            },
            immediate: true,
        },
    },
    computed: {
        permissions(){
            return this.$store.getters.userInfo.permissions;
        },
        platformCenter(){
            return this.$store.getters.configMap['platformCenter'];
        },
        userInfo(){
            return this.$store.getters.userInfo;
        },
        hasNeedHandlePermiss(){
            // console.log(this.$route);
            let flag = false;
            if(this.$route.path.endsWith('/cadre')){
                flag = this.permissions.includes('/hrms/cadre/user/change/search-page');
            }else if(this.$route.path.endsWith('/dean')){
                flag = this.permissions.includes('/hrms/dean/user/change/search-page');
            }else{
                flag = this.permissions.includes('/hrms/user/change/search-page');
            }
            return this.hasMenu && flag;
        },
        hasMenu(){      //  是否有顶部菜单,在系统设置的时候没有
            return this.$route.matched[0].name !== 'layoutSystem';
        },
        title(){
            let title = '';
            switch(this.$route.matched[0].name){
                case 'layout':
                    title = '中院班子基层院长政治素质和工作业绩档案';
                    document.title = this.$route.matched[1].meta.title + '-' + title;
                break;
                case 'layoutCadre':
                    title = '全省法院优秀年轻干部信息库';
                    document.title = this.$route.matched[1].meta.title + '-' + title;
                break;
                case 'layoutDean':
                    title = '全省基层法院院长梯队库';
                    document.title = this.$route.matched[1].meta.title + '-' + title;
                break;
                case 'layoutSystem': 
                    title = '系统设置';
                    document.title = title;
                break;
            }
            return title;
        },
    },
    methods: {
		toEntrance() {
			window.open('/entrance', '_blank')
		},
        userInfoView(){
            const token = this.$store.getters.token;
            window.location = `${this.platformCenter}/user?WHRP_TOKEN=${token}`;
        },
        async logout(){
            this.$store.dispatch('common/logout');
			this.$store.commit('status/SET_MENUVLAUE', null)
        },
        menuInput(val){
            if(val !== this.$route.name){
                this.$router.push({
                    name: val
                })
            }
            this.value = val;
        },
        getRouteList(){
            traverse(this.$router.options.routes,route => {
                if(route.name === this.$route.matched[0].name){       //  matched包含所有上级route
                    this.routeList = route.children.map(item => {
                        return {
                            label: item.meta.title,
                            value: item.name,
                            link: item.link,
                        }
                    });
                }
            })
        },
        needHandleClick(){
            this.$router.push({
                path: '/need-handle'
            })
        },
    },
    mounted(){


        
    },
}
</script>

<style lang="less" scoped>
.layout{
    height: 100vh;
    background-color: #EFF3F7;
    .topbar-container{
        height: 50px;
        .right{
            display: flex;
            justify-content: flex-end;
            > div{
                margin-left: 24px;
                cursor: pointer;
            }
            .need{
                width: 78px;
                height: 32px;
                border-radius: 40px;
                background-color: rgba(0, 255, 178, 0.15);
                border: 1px solid #008B61;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                .line{
                    display: inline-block;
                    width: 1px;
                    height: 14px;
                    margin: 0 3px;
                    background-color: #008B61;
                }
                .count{
                    font-size: 15px;
                    color: #FF2525;
                }
            }
			.icons {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0;
				margin-left: 0;
				.icon {
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-left: 24px;
				}
			}

            .user{
                display: flex;
                align-items: center;
                img{
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    .body-container{
        height: calc(100% - 50px);
        > div{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    }
}

.detail-container{
    height: 560px;
    overflow: auto;
}
</style>